<template>
  <div class="row">
    <div :class="'col-lg-12'">
      <div class="card">
        <div class="card-body">
          <form class="form-horizontal">
            <div class="form-body">
              <h3 class="box-title">Disposisi</h3>
              <hr class="m-t-0" />
              <template>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      Nomor Surat
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      <b>{{ this.surat.agenda_number }}</b>
                    </label>
                  </div>
                </div>
                <hr class="m-t-10" />
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      Perihal
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      <b>{{ disposition.description }}</b>
                    </label>
                  </div>
                </div>
                <hr class="m-t-10" />
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      Penerima Sebelumnya
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      Struktural
                      <ul>
                        <li
                          v-for="val in disposition.penerima_stk"
                          :key="val.value"
                        >
                          <b>{{ val.text }}</b>
                        </li>
                      </ul>
                      Personal
                      <ul>
                        <li
                          v-for="val in disposition.penerima_prs"
                          :key="val.value"
                        >
                          <b>{{ val.text }}</b>
                        </li>
                      </ul>
                    </label>
                  </div>
                </div>
                <hr class="m-t-10" />
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      Tambahkan Penerima :
                      <small style="color: red">*</small>
                    </label>
                  </div>
                </div>
                <!-- <div class="row" v-if="userDisposisi.length > 0"> -->
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-1',
                        ]"
                      ></label>
                      <div class="col-md-9 col-sm-9">
                        <div class="checkbox">
                          <input
                            @click="checkAll()"
                            v-model="isCheckAll"
                            id="index"
                            type="checkbox"
                          />
                          <label for="index">Centang Semua</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    :class="'col-md-6'"
                    v-for="(value, index) in userDisposisi"
                    :key="index"
                  >
                    <div class="row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-1',
                        ]"
                      ></label>
                      <div :class="'col-md-11 col-sm-12'">
                        <div>
                          <!-- <input
                            :id="'checkbox-a-' + index"
                            :value="value"
                            v-model="addedUserFirst"
                            v-if="addedUser[index]"
                            @change="updateValue($event, index)"
                            type="checkbox"
                            class="m-r-5"
                          /> -->
                          <!-- :disabled="payload.personal.length > 0" -->
                          <input
                            class="m-r-5"
                            :id="'checkbox-' + index"
                            :value="value"
                            v-model="addedUser[index]"
                            @change="updateCheckall($event, index)"
                            type="checkbox"
                          />
                          <label :for="'checkbox-' + index">{{
                            value.text
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="m-t-10" />
                <!-- <div class="row" v-if="this.surat.login == 'Eselon IV'"> -->
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      Personal
                    </label>
                    <div :class="[{ invalid: isInvalidPersonal }, 'col-md-12']">
                      <Multiselect
                        v-model="payload.personal"
                        label="text"
                        track-by="value"
                        placeholder="Pilih Personal"
                        open-direction="bottom"
                        :options="dropdownPersonal.personalList"
                        :multiple="true"
                        :searchable="true"
                        :value="dropdownPersonal.value"
                        :loading="dropdownPersonal.isLoadingPersonal"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="false"
                        :options-limit="300"
                        :max-height="600"
                        :show-no-results="true"
                        :hide-selected="false"
                        @input="onChangePersonal"
                        @search-change="personal"
                      >
                        <span slot="noResult">Oops! No Data found.</span>
                      </Multiselect>
                    </div>
                  </div>
                </div>
                <hr class="m-t-10" />
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']">
                      Isi Disposisi
                      <small style="color: red">*</small>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div
                    :class="'col-md-6'"
                    v-for="(row, index) in disposisiCommand"
                    :key="index"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <label :class="['control-label', 'col-md-12']">
                          <div v-for="(r, i) in row.data" :key="i">
                            <div class="checkbox">
                              <input
                                :value="r.value"
                                :checked="isCheckedDispo(r.value)"
                                :id="r.value"
                                type="checkbox"
                                @change="addCommand($event, r.value)"
                              />
                              <label :for="r.value">{{ r.text }}</label>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template>
                <div class="row m-t-10">
                  <div class="col-md-12">
                    <label :class="['control-label', 'col-md-12']"
                      >Catatan Disposisi</label
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 m-t-10 m-b-10">
                    <wysiwyg v-model="myHTML" />
                  </div>
                </div>
              </template>
            </div>
            <hr />
            <div class="form-actions">
              <div class="row">
                <div class="col-md-offset-3 col-md-9">
                  <button
                    type="button"
                    @click="submit()"
                    class="btn btn-success"
                    title="Kirim"
                  >
                    <i class="mdi mdi-send"></i>
                    Kirim</button
                  >&nbsp;
                  <button
                    type="button"
                    @click="goBack()"
                    class="btn btn-inverse"
                    title="Cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <h3>Tes</h3> -->
    <!-- <h6>{{ this.$store }}</h6> -->
  </div>
</template>
<script>
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  components: {
    Input,
    Multiselect,
  },
  computed: {
    isInvalid() {
      return this.isTouched && this.value.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidPersonal() {
      return (
        this.dropdownPersonal.isTouched &&
        this.dropdownPersonal.value.length === 0
      );
    },
  },
  data() {
    return {
      userDisposisi: [],
      addedCommand: [],
      isCheckAll: false,
      myHTML: "",
      surat: [],
      disposition: [],
      addedUser: [],
      addedUserFirst: [],
      disposisiCommand: [],
      payload: {
        personal: [],
      },
      dropdownPersonal: {
        isTouched: false,
        personalList: [],
        value: [],
        isLoadingPersonal: false,
      },
    };
  },
  async mounted() {
    var query = "";
    this.getSurat(query);
    this.getIntruksi(query);
    this.getUserDispo(query);
    this.personal(query);
  },
  methods: {
    isCheckedDispo(value) {
      let isChecked = false;
      let data = this.addedCommand.filter((element) => {
        // console.log(element);
        let checked = false;
        if (element == value) {
          checked = true;
        }
        return checked == true;
      });
      if (data.length) {
        isChecked = true;
      }
      console.log(value);
      return isChecked;
    },
    addCommand(e, value) {
      if (e.target.checked) {
        this.addedCommand.push(value);
      } else {
        this.addedCommand = this.addedCommand.filter((element) => {
          return element != value;
        });
      }
    },
    personal(query) {
      this.dropdownPersonal.isLoadingPersonal = true;
      axios
        .get(
          `document_in/list_not_send?s=${query}&disposition=${this.$route.params.id}`
        )
        .then((res) => {
          this.dropdownPersonal.personalList = res.data.items;
          this.dropdownPersonal.isLoadingPersonal = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    getSurat(query) {
      let vm = this;
      axios.get(`/disposition_in/${this.$route.params.id}`).then((response) => {
        this.surat = response.data.data.surat;
        this.disposition = response.data.data;
        this.addedCommand = this.disposition.datadispo.map((element) => {
          return element.value;
        });
        let user = JSON.parse(localStorage.getItem("user"));
        // vm.myHTML = vm.disposition.note_creator;
        vm.myHTML = vm.disposition.catatan_disposisi;
        // for(let element in vm.disposition.notes){
        //   if(user.unit.includes(element)){
        //     vm.myHTML = vm.disposition.notes[element];
        //   }
        // }
      });
    },
    getUserDispo(query) {
      var user = JSON.parse(localStorage.getItem("user"));
      axios
        .post(
          `disposition_in/disposition_not_send?s=${query}&structural=${user.structural_position_id}&unit_id=${user.unit_id}&disposition=${this.$route.params.id}`
        )
        .then((res) => {
          res.data.items.forEach((row) => {
            if (user.unit_id != row.value) {
              this.userDisposisi.push({
                value: row.value,
                text: row.text,
                defaultValue: 1,
              });
            }
          });
        });
    },
    getIntruksi() {
      axios.get(`document_statuses/list`).then((res) => {
        this.disposisiCommand = res.data.items;
      });
    },
    onTouchPersonal() {
      this.dropdownPersonal.isTouched = true;
    },
    onChangePersonal(value) {
      this.dropdownPersonal.value = value;
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.addedUser = [];
      this.addedUserFirst = [];
      if (this.isCheckAll) {
        for (var key in this.userDisposisi) {
          this.userDisposisi[key].defaultValue = 1;
          this.addedUser.push(this.userDisposisi[key]);
        }
      }
    },
    updateValue(e, index) {
      if (e.target.checked) {
        this.addedUser[index].defaultValue = 2;
      } else {
        this.addedUser[index].defaultValue = 1;
      }
    },
    updateCheckall(e, index) {
      let data = e.target._value;

      if (this.addedUser[index] && data) {
        this.addedUser[index] = data;
      }
      let tempData = [];
      this.addedUser.forEach((value) => {
        if (value != null || value != false || value != undefined) {
          tempData.push(value);
        }
      });
      if (tempData.length == this.userDisposisi.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    goBack() {
      this.$store.dispatch("dispositionSend/onCancel");
    },
    submit() {
      const addedUser = [];
      this.addedUser.forEach((value) => {
        if (value != null || value != false || value != undefined) {
          addedUser.push(value);
        }
      });
      const disposition = localStorage.getItem("disposition");
      const payload = {
        user: addedUser,
        disposisi: disposition,
        command: this.addedCommand,
        note: this.myHTML,
        personal: this.payload.personal,
        is_send: true,
      };
      const data = JSON.stringify(payload);
      console.log(data);
      this.$validator.validateAll().then((success) => {
        if (success) {
          this.$store.dispatch("dispositionSend/submitDisposisi", data);
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>